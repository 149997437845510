@import '../node_modules/bootstrap/dist/css/bootstrap-grid.css';
@import '../node_modules/bootstrap/dist/css/bootstrap-utilities.css';
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/forms';

:root {
  --background-color: #ffffff;
  --default-font-family: 'Montserrat', sans-serif;
  --primary-rgb: 115, 197, 194;
  --secondary-rgb: 214, 86, 64;
  --tertiary-rgb: 191, 179, 124;
  --quaternary-rgb: 52, 37, 96;
  --primary: rgb(var(--primary-rgb));
  --secondary: rgb(var(--secondary-rgb));
  --tertiary: rgb(var(--tertiary-rgb));
  --quaternary: rgb(var(--quaternary-rgb));
}

:root,
body {
  background-color: var(--background-color);
  font-weight: 400;
}

body {
  font-family: var(--default-font-family);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@for $i from 1 to 6 {
  h#{i},
  .h#{i} {
    font-weight: 500;
  }
}

.right-controls,
.left-controls {
  position: fixed;
  top: 0;
  pointer-events: none;
  user-select: none;
  z-index: 100;

  .control {
    pointer-events: all;
  }
}

.right-controls {
  right: 0;
}

.left-controls {
  left: 0;
}

i.bi {
  font-size: 16px;
}

.section-title {
  font-size: calc(22.2px + 3.28235vw);
  font-weight: 100;
  margin-bottom: calc(16.52174px + 1.08696vw);

  @media (max-width: 575px) {
    font-size: calc(22.2px + 1.28235vw);
    font-weight: bold;

    span {
      font-size: 18px !important;
    }
  }

  @media (max-width: 350px) {
    font-size: 22px;
  }
}

.section-content {
  font-size: 16px;
  line-height: 1.6em;

  @media (max-width: 575px) {
    font-size: 15px;
    line-height: 1.2;
  }

  // @media (max-width: 350px) {
  //   font-size: 22px;
  // }
}

.list-style-none {
  list-style: none;
  padding-left: 0;
}

.flex-1 {
  flex: 1;
}

.lg-form {
  @media (max-width: 575px) {
    transform: scale(0.79) translateY(-10%);
    margin: 0 -40px;
  }
}
.next-button {
  position: fixed !important;
  bottom: 30px !important;
  left: calc(50% - 135px);

  @media (max-width: 575px) {
    bottom: 10px !important;
    transform: scale(0.8);
  }
}

.morph-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: background-color 1.5s;

  .morph {
    position: relative;
    height: 100%;
    width: 100%;
    flex: none;
  }
}

.form-floating > .form-control:not(textarea), .form-floating > .form-select {
  height: 3.4rem !important;
  line-height: 1.15 !important;
}