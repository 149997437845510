.contact-page {
  @media (max-width: 425px) {
    .section-description {
      display: none;
    }
    .section-title {
      margin-bottom: 0;
    }
    .lg-form {
      transform: scaleY(0.7) scaleX(0.8) translateY(-20%);
    }

    // .content {
    //   height: unset !important;
    // }
  }

  @media (max-width: 350px) {
    .content {
      transform: translateY(-30px);
    }
    .section-description {
      .lg-form {
        transform: scaleY(0.7) scaleX(0.8) translateY(-20%);
      }
    }
  }

  a {
    color: var(--primary);
  }
}
