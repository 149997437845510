.vertical-slides {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  & > .vertical-slider {
    height: 100%;
    & > .vertical-slide {
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      transition: transform 1s;
      will-change: transform;
      opacity: 0;
      &.selected {
        z-index: 11;
      }

      &.selected,
      &.previous {
        opacity: 1;
      }

      & > .vertical-slide-content {
        position: absolute;
        backface-visibility: hidden;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        overflow: hidden;

        & > .vertical-slide-container {
          position: absolute;
          backface-visibility: hidden;
          top: 0;
          left: 0;
          z-index: 100;
          width: 100%;
          height: 100%;
          overflow: hidden;
          padding: 110px calc(8% + 15px);

          @media (max-width: 575px) {
            padding: 40px calc(7% + 15px);
          }

          & > .content {
            padding: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            position: relative;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }

      &.backward {
        transform: translate3d(0,-100%,0);
      }
      &.forward {
        transform: translate3D(0,100%,0);
      }

      // ANIMATED
    }
  }

  

  & > .vertical-slider ~ nav {
    right: 0;
    height: 100%;
    z-index: 100;
    padding: 70px 4%;
    pointer-events: none;
    transition: opacity .5s;
    transform: translate3d(0,0,0);
    position: fixed;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
    & > .vertical-navigation {
      display: table;
      height: 100%;
      ul {
        padding-left: 0;
        list-style: none;
        display: table-cell;
        vertical-align: middle;
        
        &:not(.sliding) {
          pointer-events: all;
        }

        li {
          margin: 0 1px 0 2px;
          padding: 2px 9px;
          text-align: right;
          font-size: 0;
          fill: #fff;
          display: block;
          background: 0 0;
          cursor: pointer;
          position: relative;

          &.selected:after {
            height: 60px;
          }

          &::after {width: 4px;
            display: inline-block;
            transition: .5s;
            text-align: right;
            background: #fff;
            border-radius: 1px;
            border: none;
            height: 20px;
            content: ' ';
          }
        }
      }
    }
  }

  & > .slider.horizontal ~ nav {
    bottom: 0;
    width: 100%;
    z-index: 100;
    padding: 4% 70px;
    pointer-events: none;
    transition: opacity .5s;
    transform: translate3d(0,0,0);
    position: fixed;
    left: 0;
    right: 0;
    box-sizing: border-box;
    & > .navigation {
      display: flex;
      justify-content: center;
      width: 100%;
      ul {
        padding-left: 0;
        list-style: none;
        display: flex;
        
        &:not(.sliding) {
          pointer-events: all;
        }

        li {
          margin: 0 1px 0 2px;
          padding: 9px 2px;
          text-align: right;
          font-size: 0;
          fill: #fff;
          display: block;
          background: 0 0;
          cursor: pointer;
          position: relative;

          &.selected:after {
            width: 60px;
          }

          &::after {
            height: 4px;
            display: inline-block;
            transition: .5s;
            text-align: right;
            background: #fff;
            border-radius: 1px;
            border: none;
            width: 20px;
            content: ' ';
          }
        }
      }
    }
  }
}

// Animations
.vertical-slide.selected > .vertical-slide-content > .vertical-slide-container.animated {
  &.fromLeft {
    & > .content > *:not(.not-animated) {
     &:not(ul) {
      transform: translate3d(-50px, 0, 0);
      opacity: 0;

      @for $i from 0 to 1000 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 100}ms;
        }
      }
     }

     &:is(ul) {
       li {
        transform: translate3d(-50px, 0, 0);
        opacity: 0;
  
        @for $i from 0 to 1000 {
          &:nth-child(#{$i}) {
            animation-delay: #{$i * 100}ms;
          }
        }
       }
     }
    }
    &.show > .content > *:not(.not-animated) {
      &:not(ul) {
        // transform: translate3d(0, 0, 0);
        // opacity: 1;
        animation-name: fromLeft;
        animation-duration: 500ms;
        // animation-delay: 0ms;
        animation-fill-mode: forwards;
      }

      &:is(ul) {
        li {
          // transform: translate3d(0, 0, 0);
          // opacity: 1;
          animation-name: fromLeft;
          animation-duration: 500ms;
          // animation-delay: 0ms;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}


@keyframes fromLeft {
  from {
    transform: translate3d(-25px, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}