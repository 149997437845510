.img-fluid {
  width: 100%;
  max-width: 100%;
}

.grid-wrapper {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-auto-rows: 200px;
	grid-auto-flow: dense;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    &.wide {
      grid-column: span 2;
    }
    &.tall {
      grid-row: span 2;
    }
    &.big {
      grid-column: span 2;
      grid-row: span 2;
    }
    
  }
}

.bg-svg {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.gallery-page {
  background-image: url(../../images/waves.svg);
  background-size: cover;
  background-attachment: fixed;
}