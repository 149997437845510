.progress {
  display: flex;
  width: 100%;
  align-items: center;

  .progress-bar {
    flex: 1;
    border-radius: 4px;
    height: 8px;
    background-color: #f0f0f0;

    .value {
      content: ' ';
      display: block;
      height: 100%;
      border-radius: 4px;
      background-color: var(--primary);
      width: 0;
      transition: width 200ms;
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0 6px;
    i {
      font-size: 24px;
    }
    // width: ;
  }
}

.configurator-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // flex: 1;

  @media (max-width: 575px) {
    flex: unset;
  }

  .configurator-option {
    background-color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 5px;
    width: calc(16% - 12.5px);
    margin: 0 0.5rem 0.5rem;
    transition: all 200ms;
    min-width: 85px;
    
    &:hover {
      cursor: pointer;
      background-color: var(--quaternary);
      border-color: var(--quaternary);
      box-shadow: 0 5px 11px 0 rgba(var(--quaternary-rgb), 0.18), 0 4px 15px 0 rgba(var(--quaternary-rgb), 0.18);
      // border-width: 4px;
    }

    .option-image {
      border-radius: 5px;
      position: relative;
      img {
        position: absolute;
        left: 5px;
        top: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
      }
      &::before {
        border-radius: 5px;
        content: '';
        display: block;
        padding-bottom: 100%;
        background-color: #fff;
      }
    }

    .option-value {
      padding: 6px 4px;
      font-size: 12px;
      color: #ffffff;
      word-wrap: break-word;
    }
  }
}

@media (max-width: 575px) {
  .steps .fs-4 {
    font-size: 1rem !important;
  }
}

// .steps {
//   height: 0;
// }

form.invalid button {
  pointer-events: none;
}

.configurator-title {
  color: var(--primary);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 -20px 1rem;

  @media (max-width: 575px) {
    font-size: 1rem;
  }
}

.steps .main-title-container {
  top: unset;
  margin-bottom: 1.5rem;
  .main-title-shadow,
  .main-title {
    font-size: 2rem;
    color: var(--primary);
    @media (max-width: 575px) {
      font-size: 1rem;
    }
  }
}

.steps .promo {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--secondary);

  @media (max-width: 575px) {
    font-size: 1rem;
  }
}