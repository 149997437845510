header {
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  transition: all 200ms;

  display: flex;
  justify-content: center;
  img {
    margin-top: 20px;
    height: 90px;
    transition: all 200ms;
    @media (max-width: 575px) {
      margin-top: 0;
      height: 70px;
    }
  }

  &.hidden {
    // opacity: 0;
    transform: scale(0.9);

    img {
      height: 70px;

      @media (max-width: 575px) {
        height: 50px;
      }
    }
  }

  // @media (max-width: 400px) {
  //   display: none;
  // }

  &.sticky {
    background-color: #ffffffdd;
  }
}
