@import '/src/colors.scss';

.btn-container {
  --btn-color: #{$primary};
  --btn-light-color: #{lighten($primary, 35%)};
  --btn-alpha-color: #{lighten($primary, 30%)};
  position: relative;
  &.btn-left,
  &.btn-right {
    position: absolute;
    width: 50px;
    height: 100px;
    top: 20px;
    pointer-events: visible;
    opacity: 1;
    visibility: visible;
    z-index: 2;

    @media (max-width: 350px) {
      top: 2px;
      transform: scale(0.85);
    }

    svg {
      overflow: visible;
    }

    .main-gooey,
    .secondary-gooey {
      fill: #ffffff;
      transition: fill 200ms ease-in, transform 400ms ease-out;
    }

    .secondary-btn,
    .main-btn {
      transition: all 200ms ease-in;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        transform: translateY(1.5px);
      }
    }

    .secondary-label span {
      display: block;
    }
    .main-label {
      display: block;
      position: absolute;
      width: auto;
      height: 55px;
      top: -1px;
      padding: 0px 60px 0px 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 55px;
      text-align: center;
      white-space: nowrap;
      /* color: #000000;
      background-color: #ffffff; */
      border-radius: 38px;
      pointer-events: none;
      z-index: -1;
      opacity: 0;
    }
    .main-label span {
      display: block;
    }

    .main-btn {
      position: absolute;
      top: 2px;
      left: 0;
      height: 47px;
      width: 47px;
      background: #ffffff;
      border: none;

      border-radius: 50%;

      i.bi {
        font-size: 24px;
        height: 24px;
        display: inline-flex;
      }
    }

    .secondary-btn {
      position: absolute;
      bottom: 11px;
      left: 9px;
      height: 31px;
      width: 31px;
      background: #ffffff;
      border: none;
      border-radius: 50%;
    }

    .btn-right svg {
      fill: #fff;
    }

    .secondary-btn,
    .secondary-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: black;
    }

    .main-shadow,
    .secondary-shadow {
      fill: rgba(0, 0, 0, 0.01);
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }
    .main-gooey,
    .secondary-gooey,
    .main-shadow,
    .secondary-shadow {
      opacity: 1;
      visibility: visible;
    }

    .secondary-label {
      display: block;
      position: absolute;
      width: auto;
      height: 40px;
      top: 55px;
      padding: 0px 45px 0px 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 40px;
      text-align: center;
      white-space: nowrap;
      border-radius: 38px;
      pointer-events: none;
      z-index: -1;
      opacity: 0;
    }

    .main-label,
    .secondary-label {
      opacity: 0;
      transform: matrix(0.3, 0, 0, 0.3, 0, 0);
      color: rgb(253, 58, 1);
      background-color: rgba(253, 58, 1, 0.2);
      transform-origin: right 50% 0px;
      transition: all 100ms ease-in;
    }

    .main-btn:hover ~ .main-label,
    .secondary-btn:hover ~ .secondary-label {
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    .main-gooey,
    .secondary-gooey {
      fill: var(--btn-light-color);
    }

    .secondary-btn,
    .main-btn {
      background-color: var(--btn-light-color);
      color: var(--btn-color);
    }

    .secondary-label,
    .main-label {
      background-color: var(--btn-alpha-color);
      color: var(--btn-color);
    }

    .secondary-btn:hover,
    .main-btn:hover {
      background-color: var(--btn-color);
      color: #fff;
    }

    .main-gooey:hover,
    .secondary-gooey:hover {
      fill: var(--btn-color);
    }

    &:hover .secondary-gooey,
    &:hover .main-gooey {
      transform: scale(0);
    }
  }

  &.btn-right {
    right: 20px;
    @media (max-width: 350px) {
      right: 2px;
    }
    .main-label {
      right: -4px;
    }

    .secondary-label {
      right: 5px;
    }
  }

  &.btn-left {
    left: 20px;
    @media (max-width: 350px) {
      left: 2px;
    }
    .main-label {
      left: -4px;
      padding: 0px 20px 0px 60px;
      transform-origin: left 50% 0px;
    }

    .secondary-label {
      left: 5px;
      padding: 0px 20px 0px 45px;
      transform-origin: left 50% 0px;
    }
  }

  .main-btn:hover {
    cursor: pointer;
  }
  .b.secondary-btn:hover {
    cursor: pointer;
  }

  .main-gooey {
    transition: transform 400ms ease-in-out;
    transform-origin: center 20%;
  }
  .secondary-gooey {
    transition: transform 400ms ease-in-out;
    transform-origin: center 80%;
  }

  svg.only-secondary {
    & ~ .secondary-label {
      top: -1px;
    }
    & ~ .secondary-btn {
      top: 2px;
    }

    .secondary-shadow {
      d: path("m 26.1 2 c 9.1 0 16.5 7.4 16.5 16.5 s -7.4 16.5 -16.5 16.5 s -16.5 -7.4 -16.5 -16.5 s 7.4 -16.5 16.5 -16.5 z");
    }
  }
}

.btn {
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  width: 270px;
  height: 46px;
  padding: 0 4px;
  border-radius: 38px;
  z-index: 3;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 65px;
    background-color: rgba(46, 75, 245, 0.15);
  }

  .btn-content-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 65px;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    transition: transform 300ms;

    .btn-content {
      font-weight: 500;
      font-style: normal;
      font-size: 11px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      z-index: 1;
    }
  }

  &:hover {
    cursor: pointer;
    .btn-content-wrapper {
      transform: translateY(3px);
    }
  }

  &.primary {
    .btn-content-wrapper {
      background-color: #{$primary};
      .btn-content {
        color: #ffffff;
      }
    }
  }
  &.secondary {
    .btn-content-wrapper {
      background-color: #{$secondary};
      .btn-content {
        color: #ffffff;
      }
    }
  }

  &.not-rounded {
    border-radius: 4px;
    &::before {
      border-radius: 4px;
    }
    .btn-content-wrapper {
      border-radius: 4px;
      &::before {
        border-radius: 4px;
      }
    
      .btn-content-wrapper {
        border-radius: 4px;
      }
    }
  }
}

.btn-huge {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: none;
  outline: none;
  user-select: none;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  padding: 0 4px;
  z-index: 3;
  background-color: transparent;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    background-color: rgba(46, 75, 245, 0.15);
  }

  .btn-content-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    transition: transform 300ms;

    .btn-content {
      font-weight: 500;
      font-style: normal;
      font-size: 11px;
      letter-spacing: 0.1em;
      z-index: 1;
    }
  }

  &:hover {
    cursor: pointer;
    .btn-content-wrapper {
      transform: translateY(3px);
    }
  }

  &.primary {
    .btn-content-wrapper {
      background-color: var(--primary);
      .btn-content {
        color: #ffffff;
      }
    }
  }
}

.primary-mask .btn-container {
  --btn-color: #{$primary};
  --btn-light-color: #{lighten($primary, 35%)};
  --btn-alpha-color: #{lighten($primary, 30%)};
}

.secondary-mask .btn-container {
  --btn-color: #{$secondary};
  --btn-light-color: #{lighten($secondary, 40%)};
  --btn-alpha-color: #{lighten($secondary, 30%)};
}

.tertiary-mask .btn-container {
  --btn-color: #{$tertiary};
  --btn-light-color: #{lighten($tertiary, 40%)};
  --btn-alpha-color: #{lighten($tertiary, 30%)};
}

.quaternary-mask .btn-container {
  --btn-color: #{$quaternary};
  --btn-light-color: #{lighten($quaternary, 40%)};
  --btn-alpha-color: #{lighten($quaternary, 30%)};
}