
.main-title-container {
  position: relative;
  .main-title {
    font-size: calc(26.4px + 2.50588vw);
    z-index: 2;
    text-align: center;
    // letter-spacing: -0.02em;
    line-height: 0.9;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
  }

  .main-title-shadow {
    position: absolute;
    text-align: center;
    // letter-spacing: -0.02em;
    line-height: 0.9;
    text-transform: uppercase;
    color: #000000;
    width: 100%;
    height: 40%;
    top: 95%;
    left: 0;
    overflow: hidden;
    opacity: .3;
    font-size: calc(26.4px + 2.50588vw);
    font-weight: bold;

    .inner {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
    }

    &.shadow-1 {
      height: 20%;
      top: 98%;
    }
    &.shadow-2 {
      top: 116%;
      height: 20%;
      opacity: .1;
    }
  }
}