@import '/src/colors.scss';

.social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 4px;
}

.social li {
  margin: 4px;
}

.social li a svg {
  fill: var(--primary);
}

.sidenav {
  --sidenav-background: #{lighten($primary, 40%)};

  position: fixed;
  height: 100%;
  max-width: 360px;
  width: 100%;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 200ms ease-in;
  z-index: 1000;

  .sidenav-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;

    path {
      fill: var(--sidenav-background);
    }
  }

  .sidenav-content {
    width: 100%;
    height: 100%;
  }

  &.open {
    transform: translateX(0);

    & ~ .sidenav-backdrop {
      display: block;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    ul {

      &.nav {
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 40%;
            width: 20%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.08);
          }
    
          a {
            color: rgba(0, 0, 0, 0.3);
            text-decoration: none;
            font-size: 16px;
            transition: color 200ms;
            font-weight: bold;

            &.active,
            &:hover {
              color: #212121;
            }
          }
        }
      }

      li.footer-link {
        position: relative;
        a {
          text-decoration: none;
          text-transform: uppercase;
          font-weight: bold;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          &::before {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            max-width: 0;
            z-index: -1;
          }

          &:hover {
            cursor: pointer;
            color: #fff !important;
            &::before {
              max-width: 360px;
              transition: max-width 200ms;
            }
          }
        }
        &.one a {
          color: $primary;
          &::before {
            background: linear-gradient(180deg, $primary 0%, $secondary 100%);
          }
        }
        &.two a {
          color: $quaternary;
          &::before {
            background: linear-gradient(180deg, $tertiary 0%, $quaternary 100%);
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: rgba(0, 0, 0, 0.08);
        }

        &:hover {
          &::before {
            content: none;
          }
        }
      }
    }
  }
}

.sidenav-backdrop {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 900;
}

.primary-mask .sidenav {
  --sidenav-background: #{lighten($primary, 40%)};
}
.secondary-mask .sidenav {
  --sidenav-background: #{lighten($secondary, 40%)};
}
.tertiary-mask .sidenav {
  --sidenav-background: #{lighten($tertiary, 40%)};
}
.quaternary-mask .sidenav {
  --sidenav-background: #{lighten($quaternary, 40%)};
}