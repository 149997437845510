.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #0000008a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;

  .modal {
    width: 100%;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 0.5rem;
    position: relative;

    @media (min-width: 575px) {
      max-width: 480px;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .modal-icon {
        width: 70%;
      }

      &.modal-error {
        .modal-icon {
          width: 75%;
        }
      }
    }

    .close-modal {
      border: none;
      outline: none;
      background-color: transparent;
      position: absolute;
      right: 1rem;
      top: 1rem;
      font-size: 24px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}