.impressum-page {
  section {
    position: relative;
    z-index: 1;
    min-height: 70vh;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    line-height: 1.6;

    h2, h3 {
      margin-bottom: 1.5rem;
    }

    &::before {
      content: ' ';
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: calc(100% - 5rem);
      // background-color: var(--quaternary);
      z-index: -1;
    }
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5rem;

      // path {
      //   fill: var(--quaternary) !important;
      // }
    }

    &:nth-child(1) {
      color: #ffffff;
      &::before {
        background-color: var(--quaternary);
      }
      svg path {
        fill: var(--quaternary);
      }
    }

    &:nth-child(2) {
      background-color: var(--primary);
      &::before {
        background-color: #ffffff;
      }
      svg path {
        fill: #ffffff;
      }
    }

    &:nth-child(3) {
     
      &::before {
        background-color: var(--primary);
      }
      svg path {
        fill: var(--primary);
      }
    }

    &:nth-child(4) {
      background-color: var(--secondary);
      &::before {
        background-color: #ffffff;
      }
      svg path {
        fill: #ffffff;
      }
    }

    &:nth-child(5) {
      // background-color: var(--secondary);
      &::before {
        background-color: var(--secondary);
      }
      svg path {
        fill: var(--secondary);
      }
    }

    &:nth-child(6) {
      background-color: #0099ff;
      &::before {
        background-color: #ffffff;
      }
      svg path {
        fill: #ffffff;
      }
    }

    &:nth-child(7) {
      color: #fff;
      background-color: #0099ff;
      &::before {
        background-color: #0099ff;
      }
      svg path {
        fill: #ffffff;
      }
    }
  }
}