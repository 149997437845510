.home-page {
  .lottie {
    position: absolute;
    top: 5%;
    bottom: 5%;
    left: 0;
    right: 0;
    transform: translateY(-50px);
    opacity: 0.95;

    & > div {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: 0px auto;
      outline: none;
    }
  }
  .description {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .open-button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 24px;
    z-index: 10;
  }

  @media (max-width: 575px) {
    .lottie {
      transform: none;
    }

    .description {
      bottom: 36px;
    }

    .open-button {
      bottom: 58px;
    }

    .main-title-container {
      top: 60px;
    }
  }

  .vertical-slider {
    height: 100%;
  }

  .vertical-slide-container .content {
    display: block !important;
  }

  .horizontal-slide:first-of-type .vertical-slide-container {
    padding: 70px 1.5rem !important;

    @media (max-width: 575px) {
      padding: 40px 1rem 60px !important;
    }
  }
}

.slide-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.horizontal-slide:not(.selected) .slide-background {
  animation-name: zoomOut;
  animation-delay: 0;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}

.horizontal-slide.selected .slide-background {
  animation-name: zoomIn;
  animation-delay: 1000;
  animation-duration: 1000ms;
}

@keyframes zoomIn {
  0% {
    transform: scale(5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(5);
  }
}