.horizontal-slides-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;

  & > .horizontal-slides {
    --transition: 1s;
    display: flex;
    width: 100%;
    height: 100%;
    transition: all var(--transition);
    transform: translateX(calc(attr(data-index) * 100%));
    & > .horizontal-slide {
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      overflow: hidden;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      position: relative;


      .slide-scroller {
        height: 100%;
        width: 100%;
        display: grid;
        align-items: center;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
        
        
      }

      .slide-content {
        
      }
    }
  }

  & > .horizontal-pagination-container {
    background-color: rgba(var(--primary-rgb), 0.5);
    transform: translateX(-50%);
    position: absolute;
    bottom: 30px;
    left: 50%;
    padding: 10px;
    border-radius: 28px;

    @media (max-width: 575px) {
      transform: translateX(-50%) scale(0.7);
      bottom: 10px;
    }
    & > .horizontal-pagination {
      position: relative;
      
      ul {
        list-style: none;
        padding-left: 0;
        display: flex;
        // grid-template-columns: 60px 60px 60px 60px;
  
        li {
          position: relative;
          .slide-link {
            height: 30px;
            width: 63px;
            border: none;
            background-color: transparent;
            position: relative;
            .btn-text {
              max-width: 200px;
              white-space: initial;
              text-align: center;
              &::before {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(var(--light-rgb), 0.5);
                border-radius: 24px;
                z-index: -1;
              }
            }

            &:hover {
              cursor: pointer !important;
            }
  
            &::before,
            &::after {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              content: ' ';
              display: block;
              border-radius: 50%;
              transition: all 200ms;
            }
  
            &::before {
              background-color: #fff;
              width: 5px;
              height: 5px;
            }
  
            &::after {
              width: 0;
              height: 0;
              background-color: var(--primary);
            }
  
            &:hover {
              &::before {
                width: 15px;
                height: 15px;
              }
              &::after {
                width: 5px;
                height: 5px;
              }
            }
          }

          .pagination-tooltip {
            position: absolute;
            display: block;
            top: -70px;
            left: 50%;
            // padding: 15px 30px;
            border-radius: 38px;
            white-space: nowrap;
            background: #ffffff;
            transform: translate3d(-50%, -10px, 0);
            pointer-events: none;
            user-select: none;
            opacity: 0;
            transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1), transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
            z-index: 2;

            .tooltip-shadow {
              position: absolute;
              display: block;
              top: 2px;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 38px;
              background-color: rgba(0, 0, 0, 0.651);
              z-index: -1;
              &::after {
                content: '';
                position: absolute;
                bottom: -8px;
                left: calc(50% - 8px);
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid rgba(0, 0, 0, 0.651);
              }
            }

            .tooltip-background {
              display: block;
              // position: absolute;
              // width: 100%;
              // height: 100%;
              // top: 0;
              // left: 0;
              border-radius: 38px;
              background-color: white;
              z-index: 0;
              padding: 15px 30px;

              & > span {
                position: relative;
                display: inline;
                font-size: 10px;
                color: #000000;
                text-align: center;
                text-transform: uppercase;
                z-index: 2;
              }
            }

            &::after {
              content: '';
              position: absolute;
              bottom: -8px;
              left: calc(50% - 8px);
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid #ffffff;
            }
          }

          &:hover {
            .pagination-tooltip {
              transform: translate3d(-50%, 0, 0);
              opacity: 1;
            }
          }
        }
  
       
      }
  
      .tabs {
        position: relative;
      }
  
      .current-tab {
        height: 30px;
        width: 63px;
        border: none;
        background-color: var(--primary);
        position: relative;
        border-radius:  27px;
        position: absolute;
        top: 0;
        transition: all 200ms;
        color: #fff;

        &::before {
          content: ' ';
          display: block;
          position: absolute;
          top: 5px;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 65px;
          background-color: rgba(var(--primary-rgb), 0.15);
        }
  
        i {
          transition: margin-right 200ms;
        }

        .btn-content-wrapper {
          display: none;
        }
  
        &:hover {
          cursor: grabbing;
  
          i {
            &:first-of-type {
              margin-right: 10px;
            }
          }
  
          
        }

        &.start  {
          border: none;
          outline: none;
          background-color: transparent;
          display: flex;
          justify-content: space-around;
          align-items: center;
          bottom: 0px;
          width: calc(100% + 20px);
          height: calc(100% + 20px);
          padding: 0 4px;
          border-radius: 38px;
          z-index: 3;
          top: -13px !important;
          left: -10px !important;

          & > i { display: none; }

          &::before {
            content: ' ';
            display: block;
            position: absolute;
            top: 5px;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 65px;
            background-color: rgba(46, 75, 245, 0.15);
          }
        
          .btn-content-wrapper {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 65px;
            justify-content: center;
            align-items: center;
            background-color: var(--primary);
            transition: transform 300ms;
        
            .btn-content {
              font-weight: 500;
              font-style: normal;
              font-size: 11px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              z-index: 1;
            }
          }
        
          &:hover {
            cursor: pointer;
            .btn-content-wrapper {
              transform: translateY(3px);
            }
          }
        
          &.primary {
            .btn-content-wrapper {
              background-color: #2E4BF5;
              .btn-content {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.horizontal-slide .vertical-slides,
.horizontal-slide .vertical-slide {
  position: relative !important ;
}

.horizontal-slides:not(.show-content) > .horizontal-slide.selected .vertical-slide .vertical-slide-container.animated > .content > *{
  animation-name: none !important;
}
